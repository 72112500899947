import React, { useEffect } from "react";
import CheckoutNavbar from "../checkout-navbar/checkout-navbar";
import "./secondary-pages-header.scss";
import checkedImagewhite from "../../assets/checkedIconWhite.webp";

import BackgroundImage from "gatsby-background-image"


const SecondaryPagesHeader = ({
  props,
  title,
  origin,
  description,
  checks,
  check,
  image,
  background,
  price,
  priceBackground,
  imgwebp,
  imgpng,

}) => {
  let startingPrice = 0;
  let display;




  if (
    typeof window !== "undefined" &&
    //wedding does not have a context
    window.localStorage.getItem(`${origin}` && origin !== "Wedding")
  ) {

    startingPrice = localStorage.getItem(`${origin}`);
  }
  if (
    typeof window !== "undefined" &&
    window.localStorage.getItem("getQuote")
  ) {
    display = localStorage.getItem("getQuote");
  }


  var bgFluidImage = { fluid: { srcWebp: imgwebp, src: imgpng } }

  useEffect(() => {
    if (bgFluidImage) {
      const preloadLink = document.createElement("link");
      preloadLink.href = bgFluidImage;
      preloadLink.rel = "preload";
      preloadLink.as = "image";
      document.head.appendChild(preloadLink);
    }


  }, [])


  return (



    <header className="header header-secondary-page">
      <BackgroundImage Tag="section" fluid={bgFluidImage.fluid} role="img" aria-label="test" keepStatic="true">

        <div
          className={title === "Wedding Insurance" ? `background-image` : `background-image-normal`}>

          <div
            className={startingPrice || price ? "uk-container uk-child-width-1-2@l uk-child-width-1-2@s uk-child-width-1-1@xs  header-container" : "uk-container uk-child-width-1-1  header-container"}
            data-uk-grid="true"
          >
            <div className="first-column">
              <h1>{title}</h1>
              <p>{description}</p>

              <div className="check-list">
                {/* {
                console.log(checks)
                } */}
                {

                  checks ? checks.map((item, index) => {
                    return (
                      <div>
                        <div className="display-flex">
                          <img src={checkedImagewhite} alt="header-check-list" className="check-list-img" />
                          <p className='checked-paragraph-pledge'>{item.check}</p>
                        </div>
                      </div>
                    )
                  }) : ""


                }
              </div>

              {
                title === "Wedding Insurance" ?
                  <div className="quote-btn-container" id="padding-quote-btn">
                    <a className="quote-wedding-btn" href="/wedding-checkout">Get A Quote →</a>
                  </div>
                  : ""
              }
              <br></br>
            </div>


            {title !== "About Us" &&
              title !==
              `Our Pledge` &&
              // title !== "Wedding Insurance" &&
              title !== "Privacy & Cookie Policy" ? (
              <div className="special-price-container">
                <div className="outer-circle">
                  <div className="starting_price_container">
                    <div
                      className="priceBackground"
                      style={{
                        backgroundPosition: "center ",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        opacity: "0.2",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        transform: "scale(1.3)",
                      }}
                    ></div>
                    <p>
                      {" "}
                      <span className="starting_price_text">From</span>{" "}
                      <span className="span_price">
                        £{display && startingPrice > 0 ? startingPrice.toFixed(2) : price.toFixed(2)}
                      </span>{" "}
                      <span className="ipt-style"> inc. IPT</span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

      </BackgroundImage>
    </header >

  )
};

export default SecondaryPagesHeader;
