import React, { useState, useEffect } from "react";
import "./checkout-navbar.scss";
import { useNewCheckoutContext } from "../../context/checkout-context-v2";
import { useProductsContext } from "../../context/products-context";
import { checkTheme } from "../../utils/informations"

const CheckoutNavbar = ({ firstStep, secondStep, thirdStep, checkoutType }) => {

    const { checkoutStep } = useNewCheckoutContext();
    const { step } = useProductsContext();
    const [stepNav, setStepNav] = useState(0);


    useEffect(() => {
        checkoutType == "wedding" ? setStepNav(step) : setStepNav(checkoutStep)
        
    },);

    


        return (

            <div className="checkout-navbar">
                <div className="checkout-content">
                    <div className="checkout-header">
                        <div className="uk-navbar-right" style={{ marginLeft: "0px" }}>
                            <a href="/">
                                <img src={checkTheme().logo_white} width="300" alt="page logo" />
                            </a>{" "}
                        </div>
                        <div className="checkout-header-text">
                            <div><span className="" uk-icon="check"></span>
                                <p>FSCS protection</p></div>
                            <div><span className="" uk-icon="check"></span>
                                <p>Peace of mind</p></div>
                            <div><span className="" uk-icon="check"></span>
                                <p>FCA Regulated</p></div>
                        </div>
                    </div>
                    <div className="checkout-subheader">
                        <h3>Checkout</h3>
                        <div className="checkout-steps">
                            <div className={`checkout-step step-one ${stepNav >= 1 ? 'background-green' : ''}`}>
                                <div className="checkout-circle">
                                    {

                                        stepNav >= 1 ? <span className=" green-check-icon" uk-icon="check"></span> : <span className="step-number">1</span>

                                    }
                                </div>
                                <p>{firstStep}</p>
                            </div>
                            <div className={`checkout-step step-two ${stepNav >= 2 ? 'background-green' : ''}`}>
                                <div className="checkout-circle">
                                    {

                                        stepNav >= 2 ? <span className=" green-check-icon" uk-icon="check"></span> : <span className="step-number">2</span>

                                    }
                                </div>
                                <p>{secondStep}</p>
                            </div>
                            <div className={`checkout-step step-three ${stepNav >= 3 ? 'background-green' : ''}`}>
                                <div className="checkout-circle">
                                    {

                                        stepNav >= 3 ? <span className="green-check-icon" uk-icon="check"></span> : <span className="step-number">3</span>

                                    }
                                </div>
                                <p>{thirdStep}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    export default CheckoutNavbar;