import React from 'react'
import SecondaryPagesHeader from '../components/secondary-pages-header/secondary-pages-header'
import Paragraph from '../components/paragraph/paragraph'
import loadable from '@loadable/component'

import Layout from '../layouts'
import { treePlantedHeader, fullParagraphContent, headerParagraph, checkTheme } from '../utils/informations'

const FullScreenParagraph = loadable(() => import("../components/fullscreen-paragraph/fullscreen-paragraph"))

const TreePlanted = () => {
  return (
    
    <Layout pageTitle={`Tree Planted - ${checkTheme().pageTitle}`}>
      <SecondaryPagesHeader title={`Our Pledge`} background={treePlantedHeader.background} description={treePlantedHeader.description} checks={treePlantedHeader.checks} imgwebp={treePlantedHeader.imgwebp} imgpng={treePlantedHeader.imgpng}/>

      <Paragraph props={headerParagraph} origin="Tree Planted"/>

      <FullScreenParagraph props={fullParagraphContent} origin="TreePlantedPage"/>
    </Layout>
  )
}

export default TreePlanted